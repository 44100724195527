.App {
    text-align: center;
}

.App-logo {
    height: 10vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.users-edit-danger-divider-icon {
    color: red;
}
.users-edit-danger-divider-text {
    color: red;
}

.users-edit-delete-account-button {
    background-color: #820014;
    color: white;
}

.users-delete-account-button {
    background-color: #5b000c;
    color: white;
}

.edit-content-view {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.indicator-edit-formula-dnd-item {
    display: flex;
    flex-direction: column;
    width: 190px;
    padding: 10px;
    align-items: center;
    justify-content: center;
}
