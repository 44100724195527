body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
    height: '100%';
    min-height: 100vh;
}

.login {
    height: 100vh;
    background-color: gray;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-fields {
    margin-top: 40px;
}
.login-field {
    margin-top: 10px;
}

.signin-button {
    margin: 20px;
}

/* Авторизация */
@import url('./assets/font/gilroy/regular/style.css');
@import url('./assets/font/gilroy/medium/style.css');
@import url('./assets/font/gilroy/bold/style.css');
@import url('./assets/font/gilroy/extrabold/style.css');
@import url('./assets/font/gilroy/heavy/style.css');
@import url('./assets/font/gilroy/light/style.css');
.login__wrapper {
    font-family: 'Gilroy' !important;
    width: 100%;
    height: 100vh;
    background: #fff;
    background: url('./assets/images/login-bg.svg') no-repeat;
    background-position-x: 100%;
}
.login__wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #a533ff !important;
    border-color: #a533ff !important;
}
.login__wrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #a533ff !important;
}
.login__wrapper .ant-input-affix-wrapper:hover,
.login__wrapper .ant-input:hover,
.login__wrapper .ant-input:focus,
.login__wrapper .ant-input:active,
.login__wrapper .ant-input-focused,
.login__wrapper .ant-input-affix-wrapper-focused {
    border-color: #a533ff !important;
    box-shadow: none !important;
}
.login__container {
    max-width: 1568px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.login__box {
    height: 100vh;
    display: flex;
    align-items: center;
}
.login__img-wrapper {
    display: flex;
    align-items: center;
}
.login__img-box {
    width: 768px;
    min-height: 805px;
    background: url('./assets/images/login-img.png') no-repeat;
    background-position-x: 100%;
    display: flex;
    justify-content: flex-end;
}
.login__img-boxinner {
    padding-top: 160px;
    margin-right: 40px;
    width: 241px;
}
.login__img-boxinner .platform__name {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin-top: 24px;
}
.login__img-boxinner a {
    display: block;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
    margin-top: 48px;
}
.login__card {
    width: 608px;
    min-height: 200px;
    background: #fff;
    border-radius: 4px;
    padding: 64px 40px;
    filter: drop-shadow(0px 15px 40px rgba(225, 239, 243, 0.6));
}
.login__card h2 {
    font-weight: 500;
    font-size: 51px;
    line-height: 60px;
    text-transform: uppercase;
    color: #1b1b1b;
}
.login-row {
    margin-bottom: 20px;
}
.login-row:last-child {
    margin-bottom: 0;
}
.login-row .login-title {
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    color: #1b1b1b;
}
.login__btn-row {
    margin-top: 48px;
}
.login__btn {
    background: #a533ff;
    border-radius: 4px;
    min-height: 48px;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}
.login__btn:hover {
    border-color: #a533ff !important;
    color: #fff !important;
}
.login__btn:active {
    transform: scale(0.8);
}
.collapseBtn {
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    margin-left: -35px;
    margin-top: 15px;
}
.collapseBtn svg {
    transition: all 0.2s;
}
.collapseBtn:active svg {
    transform: scale(1.1);
}
.collapseBtn.active svg {
    transform: rotate(180deg);
}
@media (max-width: 1468px) {
    .login__img-wrapper {
        display: none;
    }
    .login__container {
        justify-content: center;
    }
}